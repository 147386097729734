'use client'
import { Box, Container, Paper, Typography } from '@mui/material'
import { BsLaptop, BsShieldLock, BsDatabase, BsRobot, BsSpeedometer, BsCloud } from 'react-icons/bs'
import { motion } from 'framer-motion'
import { styled } from '@mui/system'

const services = [
    {
        title: 'Mantenimiento y Reparación de Equipos',
        description: 'Servicio técnico especializado para mantener sus equipos funcionando de manera óptima. Resolvemos problemas de hardware y software, y realizamos mantenimientos preventivos para evitar fallas futuras.',
        icon: BsLaptop,
        features: [
            'Reparación de computadores y laptops',
            'Limpieza y mantenimiento preventivo',
            'Actualización de sistemas y software'
        ],
        highlight: 'Respuesta en menos de 24 horas'
    },
    {
        title: 'Seguridad Inteligente y Redes',
        description: 'Protección avanzada potenciada por IA que se adapta continuamente a las nuevas amenazas. Implementamos redes que aprenden y se optimizan automáticamente.',
        icon: BsShieldLock,
        features: [
            'Detección de amenazas con IA',
            'Autoconfiguración de redes',
            'Monitoreo 24/7 automatizado'
        ],
        highlight: 'Protección proactiva contra el 99.9% de amenazas'
    },
    {
        title: 'Respaldo y Recuperación Inteligente',
        description: 'Sistema de respaldo con IA que prioriza datos críticos y optimiza el almacenamiento. Recuperación predictiva que minimiza el tiempo de inactividad.',
        icon: BsDatabase,
        features: [
            'Priorización inteligente de datos',
            'Compresión adaptativa',
            'Recuperación predictiva'
        ],
        highlight: 'Recuperación hasta 5 veces más rápida'
    },
    {
        title: 'Automatización de Procesos',
        description: 'Transforme sus operaciones con automatización inteligente. Identificamos y optimizamos procesos repetitivos para aumentar la eficiencia.',
        icon: BsRobot,
        features: [
            'Automatización de tareas repetitivas',
            'Flujos de trabajo inteligentes',
            'Integración con sistemas existentes'
        ],
        highlight: 'Aumento del 60% en productividad'
    },
    {
        title: 'Optimización de Rendimiento',
        description: 'Mejora continua del rendimiento mediante análisis de IA. Identificamos cuellos de botella y optimizamos recursos automáticamente.',
        icon: BsSpeedometer,
        features: [
            'Análisis predictivo de rendimiento',
            'Optimización automática de recursos',
            'Reportes detallados en tiempo real'
        ],
        highlight: 'Mejora del 40% en velocidad de sistemas'
    },
    {
        title: 'Soluciones Cloud Inteligentes',
        description: 'Implementación y gestión de servicios cloud optimizados por IA. Escalamiento automático y gestión eficiente de recursos.',
        icon: BsCloud,
        features: [
            'Escalado automático inteligente',
            'Optimización de costos cloud',
            'Gestión predictiva de recursos'
        ],
        highlight: 'Reducción del 30% en costos cloud'
    }
]

// Create a motion-wrapped Paper component
const MotionPaper = motion(Paper);

// Then style it
const ServiceCard = styled(MotionPaper)(({ theme }) => ({
    padding: theme.spacing(4),
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    transition: 'transform 0.3s ease-in-out',
    background: 'linear-gradient(145deg, #ffffff 0%, #f8f9fa 100%)',
    '&:hover': {
        transform: 'translateY(-8px)',
    }
}))

const HighlightBox = styled(Box)(({ theme }) => ({
    backgroundColor: '#43AD4C20',
    color: '#0E402D',
    padding: theme.spacing(1, 2),
    borderRadius: theme.spacing(1),
    marginTop: theme.spacing(2),
    width: '100%',
    textAlign: 'center',
    fontWeight: 600
}))

export default function Services() {
    return (
        <Box id="services" sx={{ py: 12, backgroundColor: '#f8f9fa' }}>
            <Container maxWidth="lg">
                <Box sx={{ mb: 8, textAlign: 'center' }}>
                    <Typography
                        variant="h3"
                        gutterBottom
                        sx={{
                            fontWeight: 700,
                            background: 'linear-gradient(90deg, #0E402D, #43AD4C)',
                            backgroundClip: 'text',
                            WebkitBackgroundClip: 'text',
                            color: 'transparent',
                            mb: 3
                        }}
                    >
                        Servicios Potenciados por IA
                    </Typography>
                    <Typography variant="h6" color="text.secondary" sx={{ maxWidth: 800, mx: 'auto', mb: 4 }}>
                        Transformamos su infraestructura IT con soluciones inteligentes que aprenden y se adaptan a sus necesidades
                    </Typography>
                </Box>

                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 4 }}>
                    {services.map((service, index) => (
                        <Box
                            key={index}
                            sx={{
                                width: '100%',
                                '@media (min-width: 600px)': { width: 'calc(50% - 16px)' },
                                '@media (min-width: 900px)': { width: 'calc(33.333% - 16px)' }
                            }}
                        >
                            <ServiceCard
                                initial={{ opacity: 0, y: 20 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ delay: index * 0.1 }}
                                elevation={2}
                            >
                                <Box sx={{ color: '#43AD4C', mb: 3 }}>
                                    <service.icon size={48} />
                                </Box>
                                <Typography variant="h5" gutterBottom align="center" sx={{ fontWeight: 600, color: '#0E402D' }}>
                                    {service.title}
                                </Typography>
                                <Typography variant="body1" align="center" sx={{ mb: 3, color: 'text.secondary' }}>
                                    {service.description}
                                </Typography>
                                <Box sx={{ width: '100%', mb: 3 }}>
                                    {service.features.map((feature, idx) => (
                                        <Typography
                                            key={idx}
                                            variant="body2"
                                            sx={{
                                                mb: 1,
                                                display: 'flex',
                                                alignItems: 'center',
                                                '&:before': {
                                                    content: '"•"',
                                                    color: '#43AD4C',
                                                    fontWeight: 'bold',
                                                    marginRight: 1
                                                }
                                            }}
                                        >
                                            {feature}
                                        </Typography>
                                    ))}
                                </Box>
                                <HighlightBox>
                                    {service.highlight}
                                </HighlightBox>
                            </ServiceCard>
                        </Box>
                    ))}
                </Box>
            </Container>
        </Box>
    );
}