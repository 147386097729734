'use client';
import { Box, Container, Grid, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import { BsLightbulb, BsGlobe, BsStars, BsPeople, BsShieldCheck, BsGraphUp } from 'react-icons/bs';
import { styled } from '@mui/system';

const FeatureCard = styled(motion.div)(({ theme }) => ({
    padding: theme.spacing(3),
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    gap: theme.spacing(2),
}));

const StatsBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2),
    backgroundColor: 'rgba(67, 173, 76, 0.1)',
    borderRadius: theme.spacing(1),
    '&:hover': {
        backgroundColor: 'rgba(67, 173, 76, 0.15)',
        transform: 'translateY(-5px)',
    },
    transition: 'all 0.3s ease',
}));

// Agregar nuevo styled component para los colores de Colombia
const ColombianFlag = styled(Box)({
    display: 'flex',
    gap: '4px',
    marginBottom: '16px',
    '& > div': {
        height: '4px',
        flex: 1,
        borderRadius: '2px',
    }
});

export default function PublicAboutUs() {
    return (
        <Box id="about-us" sx={{ py: 12, backgroundColor: '#fff' }}>
            <Container maxWidth="lg">
                <Grid container spacing={6}>
                    {/* Main Content */}
                    <Grid
                        size={{
                            xs: 12,
                            md: 6
                        }}>
                        <Box sx={{ mb: 6 }}>
                            <ColombianFlag>
                                <Box sx={{ backgroundColor: '#FCD116' }} />
                                <Box sx={{ backgroundColor: '#003893' }} />
                                <Box sx={{ backgroundColor: '#CE1126' }} />
                            </ColombianFlag>

                            <Typography
                                variant="h3"
                                gutterBottom
                                sx={{
                                    fontWeight: 700,
                                    background: 'linear-gradient(90deg, #0E402D, #43AD4C)',
                                    backgroundClip: 'text',
                                    WebkitBackgroundClip: 'text',
                                    color: 'transparent',
                                    mb: 3
                                }}
                            >
                                Impulsamos el Éxito de tu Empresa
                            </Typography>
                            <Typography variant="body1" color="text.secondary" sx={{ mb: 3, fontSize: '1.1rem', lineHeight: 1.8 }}>
                                En Sispro Consultores, entendemos que la tecnología es más que herramientas: es el motor que impulsa la productividad y el crecimiento de tu negocio. Desde nuestro centro de operaciones en Bogotá, trabajamos para transformar los desafíos tecnológicos en oportunidades de mejora y eficiencia.
                            </Typography>
                            <Typography variant="body1" color="text.secondary" sx={{ mb: 3, fontSize: '1.1rem', lineHeight: 1.8 }}>
                                Nuestro impacto se refleja en resultados tangibles:
                            </Typography>
                            <Box component="ul" sx={{
                                color: 'text.secondary',
                                fontSize: '1.1rem',
                                lineHeight: 1.8,
                                mb: 3,
                                pl: 3
                            }}>
                                <li>Reducción de hasta 70% en tiempo de resolución de problemas técnicos</li>
                                <li>Optimización de procesos que aumenta la productividad en un 40%</li>
                                <li>Implementación de soluciones de IA que automatizan tareas repetitivas</li>
                                <li>Protección proactiva que previene el 99% de incidentes de seguridad</li>
                            </Box>
                            <Typography variant="body1" color="text.secondary" sx={{ fontSize: '1.1rem', lineHeight: 1.8 }}>
                                Como empresa 100% remota con talento colombiano, combinamos la calidez del servicio local con la eficiencia de las últimas tecnologías, asegurando que tu empresa esté siempre un paso adelante.
                            </Typography>
                        </Box>

                        {/* Stats actualizados para reflejar impacto */}
                        <Grid container spacing={3}>
                            <Grid
                                size={{
                                    xs: 12,
                                    sm: 4
                                }}>
                                <StatsBox>
                                    <Typography variant="h3" sx={{ color: '#43AD4C', fontWeight: 700 }}>
                                        10+
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        Años Transformando Empresas
                                    </Typography>
                                </StatsBox>
                            </Grid>
                            <Grid
                                size={{
                                    xs: 12,
                                    sm: 4
                                }}>
                                <StatsBox>
                                    <Typography variant="h3" sx={{ color: '#43AD4C', fontWeight: 700 }}>
                                        24/7
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        Soporte Disponible
                                    </Typography>
                                </StatsBox>
                            </Grid>
                            <Grid
                                size={{
                                    xs: 12,
                                    sm: 4
                                }}>
                                <StatsBox>
                                    <Typography variant="h3" sx={{ color: '#43AD4C', fontWeight: 700 }}>
                                        100%
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        Satisfacción Cliente
                                    </Typography>
                                </StatsBox>
                            </Grid>
                        </Grid>
                    </Grid>

                    {/* Features - Ahora con 6 cards */}
                    <Grid
                        size={{
                            xs: 12,
                            md: 6
                        }}>
                        <Grid container spacing={3}>
                            <Grid
                                size={{
                                    xs: 12,
                                    sm: 6
                                }}>
                                <FeatureCard
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ delay: 0.1 }}
                                >
                                    <BsLightbulb size={40} style={{ color: '#43AD4C' }} />
                                    <Typography variant="h6" sx={{ color: '#0E402D', fontWeight: 600 }}>
                                        Soluciones Inteligentes
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        Implementamos IA y automatización para multiplicar la eficiencia de tu equipo
                                    </Typography>
                                </FeatureCard>
                            </Grid>
                            <Grid
                                size={{
                                    xs: 12,
                                    sm: 6
                                }}>
                                <FeatureCard
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ delay: 0.2 }}
                                >
                                    <BsGlobe size={40} style={{ color: '#43AD4C' }} />
                                    <Typography variant="h6" sx={{ color: '#0E402D', fontWeight: 600 }}>
                                        Soporte Sin Límites
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        Atención inmediata remota y presencial cuando lo necesites
                                    </Typography>
                                </FeatureCard>
                            </Grid>
                            <Grid
                                size={{
                                    xs: 12,
                                    sm: 6
                                }}>
                                <FeatureCard
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ delay: 0.3 }}
                                >
                                    <BsStars size={40} style={{ color: '#43AD4C' }} />
                                    <Typography variant="h6" sx={{ color: '#0E402D', fontWeight: 600 }}>
                                        Optimización Continua
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        Mejoramos constantemente tus sistemas para máximo rendimiento
                                    </Typography>
                                </FeatureCard>
                            </Grid>
                            <Grid
                                size={{
                                    xs: 12,
                                    sm: 6
                                }}>
                                <FeatureCard
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ delay: 0.4 }}
                                >
                                    <BsPeople size={40} style={{ color: '#43AD4C' }} />
                                    <Typography variant="h6" sx={{ color: '#0E402D', fontWeight: 600 }}>
                                        Asesoría Estratégica
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        Guiamos tu transformación digital con experiencia y visión
                                    </Typography>
                                </FeatureCard>
                            </Grid>
                            <Grid
                                size={{
                                    xs: 12,
                                    sm: 6
                                }}>
                                <FeatureCard
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ delay: 0.5 }}
                                >
                                    <BsShieldCheck size={40} style={{ color: '#43AD4C' }} />
                                    <Typography variant="h6" sx={{ color: '#0E402D', fontWeight: 600 }}>
                                        Seguridad Garantizada
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        Protección continua de datos y sistemas críticos
                                    </Typography>
                                </FeatureCard>
                            </Grid>
                            <Grid
                                size={{
                                    xs: 12,
                                    sm: 6
                                }}>
                                <FeatureCard
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ delay: 0.6 }}
                                >
                                    <BsGraphUp size={40} style={{ color: '#43AD4C' }} />
                                    <Typography variant="h6" sx={{ color: '#0E402D', fontWeight: 600 }}>
                                        Crecimiento Sostenible
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        Soluciones escalables que crecen con tu empresa
                                    </Typography>
                                </FeatureCard>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}